<template>
  <div class="contain">
    <loading v-if="from !== 'cert' && loading"/>

    <div class="root" v-else>
      <div class="top">
        <div class="decoration"></div>
        <div class="mainlogo">
          <img :src="spliceUrl(main)" height="100%" width="100%" />
        </div>

        <div class="remark-header">
          <el-image lazy :src="spliceUrl(logo)" :fit="'contain'" width="80" height="48" class="logo"/>
          <img src="@/assets/remark/more.png" width="30" height="30" style="display: none;"/>
        </div>

        <div class="main">
          <h1 class="title">{{ productBase.productname }}</h1>
          <div class="content">{{ productBase.title }}</div>
          <div class="btns" :style="{ justifyContent: labels.length > 2 ? 'space-between' : 'space-around'}">
            <div class="btn" v-for="data in labels" :key="data.label">{{ data.label }}</div>
          </div>
          <div class="msg-btn" @click="handleGotoMsg()" v-show="isMsgActive">{{ $t("feture.ecs_bless") }}</div>
        </div>
      </div>

      <div class="route">
        <div class="show">
          <div class="route-title">
            <img src="@/assets/remark/chain.png" width="21" height="21" />
            <span>{{ $t("feture.prd_tag") }}</span>
          </div>
          <div class="chain_vid">
            <span class="r-content">{{ short(custom_id ? custom_id : vid, 8, 12) }}</span>
            <img src="@/assets/remark/right.png" width="20" height="14" @click="handleGotoChain(product_block.txId)" />
          </div>
        </div>
      </div>

      <img src="@/assets/remark/send.png" width="100%" />

      <Product @confirm="handleConfirm" :temp="temp" />

      <Footer :linkList="linkList" v-if="linkList.length !== 0" :temp="temp" />

      <div class="footer-logo">
        <img src="@/assets/remark/footer.png" height="48" />
      </div>

      <el-dialog :visible.sync="showRecordArea" top="5vh" class="input-dialog" width="100%">
        <record :records="scanCode" :total="total" :txId="bind_block.txId" :vid="vid" :createUser="createUser" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import * as R from 'ramda';
import VeTrueApi from '@/api/vetrue';
import { shortTxid } from '@/utils/bless'
import callApp from "@/services/sdk";
import { sleep } from '@/utils/common';

import { dateFormat } from '@/utils';

import record from '@/feature/remark/record.vue';
import Footer from '@/feature/remark/footer.vue';
import Product from '@/feature/remark/product.vue';
import Loading from './defaultLoading.vue';

// 寄语记录默认模版 Loading Page
export default Vue.extend({
  name: 'Remark',
  components: {
    record,
    Footer,
    Product,
    Loading,
  },
  props: ['temp'],
  data: () => ({
    showRecordArea: false,
    vid: '',
    data: {},
    main: '', // 主背景图
    logo: '',
    product: {},
    productBase: {},
    product_block: {},
    bind_block: {},
    labels: [], // 标签
    brand_custom: {},
    linkList: [], // 外部链接
    scanCode: {},
    total: 0,
    isMsgActive: false, // 是否已经激活
    from: '', // 从 bless 页过来会加上from=skip表示跳过
    createUser: '',
    loading: true,
    custom_id: '', // 新版本渲染 custom_id，有则渲染 custom_id，无则渲染 vid
  }),
  mounted() {
    const vid = this.$route.query.vid || '';
    const from = this.$route.query.from || '';
    if (!vid) {
      this.$message({ message: this.$t('feture.miss_vid'), type: "error", offset: 300, duration: 4000 , customClass: 'el-message-custom'});
      return;
    }
    this.vid = vid;
    this.from = from;

    callApp("setNavBarTitle", {
        type: "navbar.updateNavBarTitle",
        data: {
          key: "title",
          val: this.$t('feture.brd_info')
        }
      },
      "cbSetNavBarTitle",
      () => {},
      undefined
    );

    this.getProductDetail();
  },
  methods: {
    handleConfirm(data) {
      this.bind_block = data;
    },
    handleGoto(link) {
      window.location.href = link;
    },
    format(time) {
      return dateFormat(time);
    },
    short(txId, before, after) {
      return shortTxid(txId, before, after);
    },
    spliceUrl(location) {
      if (location) {
        return window.Config.bless_service_url + `/v1/public/attachment/${location}`;
      }
    },
    startLoading() {
      this.loading = true;
    },
    endLoading() {
      this.loading = false;
    },
    async getProductDetail() {
      this.startLoading();
      try {
        // 商品数据
        const data = await VeTrueApi.getProductDetail({ vid: this.vid });
        this.data = data;
        this.main = data.data.image;

        this.custom_id = R.pathOr('',['data', 'custom_id'])(data);

        const product = R.pathOr({},['data', 'dcp_data', 'tc_dcp_data', 'dcpDataInfo', 'dcpDataInfo'])(data);
        this.product = product;
        this.productBase = product.base;
        this.labels = product.custom && product.custom.Productlabels || [];

        const createUser = R.pathOr('',['data', 'create_user', 'name'])(data);
        this.createUser = createUser;

        const product_block = R.pathOr({},['data', 'dcp_data', 'tc_dcp_data', 'dcpBlockchainInfo'])(data);
        this.product_block = product_block;

        // 标签是否激活寄语
        const active = R.pathOr('',['data', 'activate_state'])(data);
        // 后台控制寄语信息隐藏或显示  true 表示显示
        const remark_record_display = R.pathOr(true, ['data', 'remark_record_display'])(data);
        // 退换货类型，null表示没有退换货操作，RETURN, BARTER 表示有退换货操作
        const returnType = R.pathOr(null, ['data', 'return_type'])(data);
        // 后台设置成显示 且 标签激活成功 且 没有退换货表示激活，其他都是没有激活
        this.isMsgActive = remark_record_display && active === 'SUCCESS' && returnType === null;

        // 品牌故事
        const brand = await VeTrueApi.getBrandStory({ vid: this.vid });
        const brand_custom = R.pathOr({},['data', 'tc_dcp_data', 'dcpDataInfo', 'dcpDataInfo', 'custom'])(brand);
        this.brand_custom = brand_custom;
        this.logo = brand_custom && brand_custom.enterpriselogos && brand_custom.enterpriselogos.filehash;
        this.linkList = brand_custom && brand_custom.Externallinks || [];

        // 扫码记录
        const history = await VeTrueApi.getVidHistory({ vid: this.vid, offset: 1, limit: 20 });
        const scanCode = R.pathOr({},['data'])(history);
        this.scanCode = scanCode.list;
        this.total = scanCode.total;

        await sleep(5000);
        this.endLoading();
      } catch (error) {
        this.endLoading();
        const msg = R.pathOr('',['response', 'data', 'detail'])(error);
        this.$message({ message: msg, type: "error", offset: 300, duration: 4000 , customClass: 'el-message-custom'});
        return;
      }
    },
    showRecord() {
      this.showRecordArea = true;
    },
    handleGotoChain(txId) {
      // window.location = `https://explore-testnet.vechain.org/transactions/${txId}`;
      // window.location = `https://explore.veworld.com/transactions/${txId}`;
      this.$router.push({
        path: '/cert',
        query:{ txId, vid: this.vid, user: this.createUser },
      })
    },
    handleGotoMsg() {
      if (this.from === 'skip') { // 从没有填写寄语的页面过来的，现在跳回去
        this.$router.push({
          path: '/bless',
          query:{ vid: this.vid, plat: this.$route.query.plat || 'WEB' },
        })
      } else {
        this.$router.push({
          path: '/survey',
          query:{ vid: this.vid, plat: this.$route.query.plat || 'WEB' },
        })
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.decoration {
  position: absolute;
  width: 100%;
  height: 163px;
  z-index: 9;
  background: linear-gradient(180deg, #000000 0%, rgba(85,85,64,0) 100%);
}
.main {
  position: relative;
  top: -50px;
  width: 100%;
  padding: 40px 30px;
  border-radius: 50px 50px 0px 0px;
  background: linear-gradient(180deg, #353535 0%, #000000 100%);
}

.input-dialog {
  background-color: rgba(0,0,0,0.8);
}

.root {
  background-color: #FFF;
}
.logo {
  height: 48px;
  width: 80px;
}

.top {
  width: 100%;
  margin: 0 auto;
}

.mainlogo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: auto;
  text-align: center;
  min-height: 600px;
}

.remark-header {
  position: absolute;
  top: 30px;
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

.title {
  font-size: 22px !important;
  color: #F1F1F1;
  font-weight: 600;
  line-height: 30px;
  text-shadow: 0px 2px 10px rgba(0,0,0,0.5000);
}

.content {
  font-size: 15px;
  color: #7F7F7F;
  font-weight: 400;
  line-height: 28px;
  margin-top: 15px;
  min-height: 100px;
}

.btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  resize: both;
}

.btn {
  margin-top: 20px;
  padding: 10px 25px;
  border: 1px solid #E3E3E3;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: rgba(255,255,255,0.8);
  background-color: rgba(255,255,255,0.1);
}

.msg-btn {
  width: 100%;
  height: 50px;
  line-height: 50px;
  margin:  0 auto;
  font-size: 16px;
  letter-spacing: 1px;
  margin-top: 32px;
  text-align: center;
  border-radius: 6px;
  background-color: #FFF;
  cursor: pointer;
  text-transform: uppercase;
}

.route {
  width: 100%;
  display: flex;
  margin-top: -40px;
}

.show {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  // padding-top: 45px;
  // &:first-child {
  //   border-right: 1px solid #979797;
  // }
}

.route-title {
  display: flex;
  align-items: center;
  & > span {
    font-size: 14px;
    color: #212121;
    margin-left: 10px;
  }
}

.chain_vid {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.r-content {
  font-size: 12px;
  color: #A9A9B0;
  font-weight: 400;
  line-height: 15px;
  word-break: break-all;
  margin: 15px 0;
  // min-height: 50px;
}

.info {
  background-color: #FFF;
}

.info-title {
  height: 60px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFF;
  & > h2 {
    font-size: 15px;
    font-weight: 400;
  }
}

.active {
  color: #EEE;
  background-color: #000;
}

.info-content {
  display: none;
}

.contentShow {
  width: 100%;
  display: block;
  background-color: #FFF;
  min-height: 300px;
}

.product-content {
  padding: 30px 15px;
  & > ul {
    padding-left: 15px !important;
    line-height: 40px;
  }
}

.intro {
  font-size: 14px;
  color: #3B3B3B;
  margin-top: 20px;
  padding: 15px;
  line-height: 24px;
  font-weight: 400;
  border: 1px solid #EBEBEB;
}

.exhibt {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: auto;
  padding: 5px 0;
  & > img {
    min-width: 112px;
    margin-right: 10px;
  }
}

.records {
  font-size: 12px;
  color: #3B3B3B;
  margin-top: 25px;
  display: flex;
  border: 1px solid #EFEFEF;
  background-color: #F6F6F6;
  & > .left {
    font-weight: 600;
    background-color: #FFF;
  }
  & > span {
    padding: 10px 5px;
  }
}

.trace-content {
  padding: 30px 15px;
}

.trace-cts {
  display: flex;
  flex-direction: column;
  & > p {
    display: flex;
    justify-content: space-between;
  }
  .left {
    font-size: 13px;
    color: #282828;
  }

  .right {
    font-size: 13px;
    color: #7F7F7F;
  }
}

.brand-info {
  background-color: #FFF;
}

.container {
  padding: 15px;
  & > h2 {
    color: #282828;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  & > p {
    font-size: 13px;
    color: #7F7F7F;
    line-height: 24px;
  }
}

.images {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  & > span {
    font-family: Futura-Heavy, Futura;
    position: absolute;
    width: 100%;
    text-align: center;
    font-size: 60px;
    text-transform: uppercase;
    color: #FFF;
    font-weight: 800;
    line-height: 80px;
    letter-spacing: 8px;
  }
}

.footer-logo {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  background-color: #000;
  & > img {
    height: 48px;
  }
}
</style>
