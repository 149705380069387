




























import Vue from 'vue';
import { sleep } from '@/utils/common';

export default Vue.extend({
  name: 'loading',
  components: {
  },
  data: () => ({
    one: { img: true, word: false },
    two: { img: true, word: false },
    three: { img: true, word: false },
  }),
  computed: {
    getScreenHeight() {
      const height = document.documentElement.clientHeight || document.body.clientHeight;
      return height;
    },
    getScreenWidth() {
      const width = document.documentElement.clientWidth || document.body.clientWidth;
      return width > 414 ? 414 : width;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.show(this.one, 2000);
      await sleep(1000);
      this.show(this.two, 2000);
      await sleep(1000);
      this.show(this.three, 2000);
    },
    async show(current: any, time: any) {
      await sleep(time);
      current.word = true;
    }
  }
})
