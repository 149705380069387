<template>
  <div class="contain">
    <default-remark v-if="temp === 'default'" :temp="temp" />
    <ginseng-remark v-if="temp === 'ginseng'" :temp="temp" />
    <mainetti-remark v-if="temp === 'mainetti'" :temp="temp" />
  </div>
</template>

<script>
import Vue from 'vue';
import * as R from 'ramda';
import callApp from "@/services/sdk";
import VeTrueApi from '@/api/vetrue';

import DefaultRemark from '@/feature/template/default/default-remark.vue';
import GinsengRemark from '@/feature/template/ginseng/ginseng-remark.vue';
import MainettiRemark from '@/feature/template/mainetti/mainetti-remark.vue';

// 寄语记录分发 Loading Page
export default Vue.extend({
  name: 'Remark',
  components: {
    DefaultRemark,
    GinsengRemark,
    MainettiRemark
  },
  data: () => ({
    temp: '',
  }),
  mounted() {
    const vid = this.$route.query.vid || '';
    const from = this.$route.query.from || '';
    if (!vid) {
      this.$message({ message: this.$t('feture.miss_vid'), type: "error", offset: 300, duration: 4000 , customClass: 'el-message-custom'});
      return;
    }
    this.vid = vid;
    this.from = from;

    callApp("setNavBarTitle", {
        type: "navbar.updateNavBarTitle",
        data: {
          key: "title",
          val: this.$t('feture.brd_info')
        }
      },
      "cbSetNavBarTitle",
      () => {},
      undefined
    );

    this.getProductDetail();
  },
  methods: {
    startLoading() {
      this.loading = true;
    },
    endLoading() {
      this.loading = false;
    },
    async getProductDetail() {
      this.startLoading();
      try {
        // 商品数据
        const data = await VeTrueApi.getProductDetail({ vid: this.vid });
        const router = R.pathOr('default', ['data', 'display_page', 'router'])(data);
        this.temp = router;

        this.endLoading();
      } catch (error) {
        this.endLoading();
        const msg = R.pathOr('',['response', 'data', 'detail'])(error);
        this.$message({ message: msg, type: "error", offset: 300, duration: 4000 , customClass: 'el-message-custom'});
        return;
      }
    }
  }
})
</script>

<style lang="scss" scoped>
</style>
