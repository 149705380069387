<template>
  <div class="contain">
    <loading v-if="from !== 'cert' && loading && mainLoading" />

    <div class="root" v-else>
      <div class="top">
        <div class="remark-header">
          <el-image lazy :src="spliceUrl(logo)" :fit="'contain'" width="80" height="48" class="logo"/>
          <img src="@/assets/remark/more.png" width="30" height="30" style="display: none;"/>
        </div>

        <div class="mainlogo">
          <el-image lazy :src="spliceUrl(main)" height="100%" width="100%" @load="handleLoadingMainImage">
            <div slot="placeholder" class="image-slot">
              <temp-loading />
            </div>
          </el-image>
        </div>

        <div class="main">
          <h1 class="loading-title">{{ productBase.productname }}</h1>
          <div class="content" v-if="productBase.title">{{ productBase.title }}</div>
          <div class="btns" :style="{ justifyContent: labels.length > 2 ? 'space-between' : 'space-around'}">
            <div class="btn" v-for="data in labels" :key="data.label">{{ data.label }}</div>
          </div>
          <div class="msg-btn" @click="handleGotoMsg()" v-show="isMsgActive">
            {{ $t("feture.ecs_bless") }}
            <i class="el-icon-right" style="font-size:20px;color: #BDEB00;margin: 1px 0 0 10px;"></i>
          </div>
        </div>
      </div>

      <div class="route">
        <div class="show">
          <img src="@/assets/template/mainetti/chain_logo.png" width="24" height="24" />
          <span class="r-title">Blockchain deposit certificate</span>
          <span class="r-content">{{ custom_id ? custom_id : vid }}</span>

          <div @click="handleGotoChain(product_block.txId)">
            <span style="color: #FFF">View Detail</span>
            <i class="el-icon-right" style="font-size:12px;color: #fff;margin: 5px 0 0 5px;"></i>
          </div>
        </div>
      </div>

      <img src="@/assets/template/mainetti/lifestyle.png" width="100%" style="margin-bottom: -4px;" />

      <Product @confirm="handleConfirm" :temp="temp" />

      <Footer :linkList="linkList" v-if="linkList.length !== 0" />

      <div class="footer-logo">
        <img src="@/assets/template/mainetti/footer.png" height="30" />
      </div>

      <el-dialog :visible.sync="showRecordArea" top="5vh" class="input-dialog" width="100%">
        <record :records="scanCode" :total="total" :txId="bind_block.txId" :vid="vid" :createUser="createUser" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import * as R from 'ramda';
import VeTrueApi from '@/api/vetrue';
import { shortTxid } from '@/utils/bless'
import callApp from "@/services/sdk";
import { sleep } from '@/utils/common';

import { dateFormat } from '@/utils';

import record from '@/feature/remark/record.vue';
import Footer from '@/feature/template/mainetti/footer.vue';
import Product from '@/feature/remark/product.vue';
import Loading from './mainettiLoading.vue';

import TempLoading from '@/feature/components/tempLoading.vue';

// 寄语记录默认模版 Loading Page
export default Vue.extend({
  name: 'Remark',
  components: {
    record,
    Footer,
    Product,
    Loading,
    TempLoading
  },
  props: ['temp'],
  data: () => ({
    showRecordArea: false,
    vid: '',
    data: {},
    main: '', // 主背景图
    mainLoading: true,
    logo: '',
    product: {},
    productBase: {},
    product_block: {},
    bind_block: {},
    labels: [], // 标签
    brand_custom: {},
    linkList: [], // 外部链接
    scanCode: {},
    total: 0,
    isMsgActive: false, // 是否已经激活
    from: '', // 从 bless 页过来会加上from=skip表示跳过
    createUser: '',
    loading: true,
    custom_id: '', // 新版本渲染 custom_id，有则渲染 custom_id，无则渲染 vid
  }),
  mounted() {
    const vid = this.$route.query.vid || '';
    const from = this.$route.query.from || '';
    if (!vid) {
      this.$message({ message: this.$t('feture.miss_vid'), type: "error", offset: 300, duration: 4000 , customClass: 'el-message-custom'});
      return;
    }
    this.vid = vid;
    this.from = from;

    callApp("setNavBarTitle", {
        type: "navbar.updateNavBarTitle",
        data: {
          key: "title",
          val: this.$t('feture.brd_info')
        }
      },
      "cbSetNavBarTitle",
      () => {},
      undefined
    );

    this.getProductDetail();
  },
  methods: {
    handleLoadingMainImage() {
      this.mainLoading = false
    },
    handleConfirm(data) {
      this.bind_block = data;
    },
    handleGoto(link) {
      window.location.href = link;
    },
    format(time) {
      return dateFormat(time);
    },
    short(txId, before, after) {
      return shortTxid(txId, before, after);
    },
    spliceUrl(location) {
      if (location) {
        return window.Config.bless_service_url + `/v1/public/attachment/${location}`;
      }
    },
    startLoading() {
      this.loading = true;
    },
    endLoading() {
      this.loading = false;
    },
    async getProductDetail() {
      this.startLoading();
      try {
        // 商品数据
        const data = await VeTrueApi.getProductDetail({ vid: this.vid });
        this.data = data;
        this.main = data.data.image;

        this.custom_id = R.pathOr('',['data', 'custom_id'])(data);

        const product = R.pathOr({},['data', 'dcp_data', 'tc_dcp_data', 'dcpDataInfo', 'dcpDataInfo'])(data);
        this.product = product;
        this.productBase = product.base;
        this.labels = product.custom && product.custom.Productlabels || [];

        const createUser = R.pathOr('',['data', 'create_user', 'name'])(data);
        this.createUser = createUser;

        const product_block = R.pathOr({},['data', 'dcp_data', 'tc_dcp_data', 'dcpBlockchainInfo'])(data);
        this.product_block = product_block;

        // 标签是否激活寄语
        const active = R.pathOr('',['data', 'activate_state'])(data);
        // 后台控制寄语信息隐藏或显示  true 表示显示
        const remark_record_display = R.pathOr(true, ['data', 'remark_record_display'])(data);
        // 退换货类型，null表示没有退换货操作，RETURN, BARTER 表示有退换货操作
        const returnType = R.pathOr(null, ['data', 'return_type'])(data);
        // 后台设置成显示 且 标签激活成功 且 没有退换货表示激活，其他都是没有激活
        this.isMsgActive = remark_record_display && active === 'SUCCESS' && returnType === null;

        // 品牌故事
        const brand = await VeTrueApi.getBrandStory({ vid: this.vid });
        const brand_custom = R.pathOr({},['data', 'tc_dcp_data', 'dcpDataInfo', 'dcpDataInfo', 'custom'])(brand);
        this.brand_custom = brand_custom;
        this.logo = brand_custom && brand_custom.enterpriselogos && brand_custom.enterpriselogos.filehash;
        this.linkList = brand_custom && brand_custom.Externallinks || [];

        // 扫码记录
        const history = await VeTrueApi.getVidHistory({ vid: this.vid, offset: 1, limit: 20 });
        const scanCode = R.pathOr({},['data'])(history);
        this.scanCode = scanCode.list;
        this.total = scanCode.total;

        await sleep(5000);
        this.endLoading();
      } catch (error) {
        this.endLoading();
        const msg = R.pathOr('',['response', 'data', 'detail'])(error);
        this.$message({ message: msg, type: "error", offset: 300, duration: 4000 , customClass: 'el-message-custom'});
        return;
      }
    },
    showRecord() {
      this.showRecordArea = true;
    },
    handleGotoChain(txId) {
      // window.location = `https://explore-testnet.vechain.org/transactions/${txId}`;
      // window.location = `https://explore.veworld.com/transactions/${txId}`;
      this.$router.push({
        path: '/cert',
        query:{ txId, vid: this.vid, user: this.createUser },
      })
    },
    handleGotoMsg() {
      if (this.from === 'skip') { // 从没有填写寄语的页面过来的，现在跳回去
        this.$router.push({
          path: '/bless',
          query:{ vid: this.vid, plat: this.$route.query.plat || 'WEB' },
        })
      } else {
        this.$router.push({
          path: '/survey',
          query:{ vid: this.vid, plat: this.$route.query.plat || 'WEB' },
        })
      }
    }
  }
})
</script>

<style lang="scss" scoped>

.main {
  position: relative;
  width: 100%;
  padding: 16px;
  background-color: #FFF;
  opacity: .85;
}

.input-dialog {
  background-color: rgba(0,0,0,0.8);
}

.root {
  background-color: #FFF;
}
.logo {
  height: 48px;
  width: 80px;
}

.top {
  width: 100%;
  margin: 0 auto;
}

.mainlogo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: auto;
  text-align: center;
}

.remark-header {
  position: relative;
  height: 56px;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loading-title {
  color: #000;
  font-weight: 800;
  font-size: 18px !important;
  line-height: 26px;
}

.content {
  font-size: 15px;
  color: #595959;
  font-weight: 400;
  line-height: 28px;
  margin-top: 15px;
  min-height: 100px;
}

.btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  resize: both;
  margin-top: 20px;
}

.btn {
  height: 24px;
  width: 85px;
  line-height: 24px;
  border: 1px solid #BFBFBF;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: rgba(45, 45, 45, 0.85);
  background: rgba(217,217,217, 0.38);
  margin-bottom: 10px;
}

.msg-btn {
  width: 100%;
  height: 50px;
  line-height: 50px;
  margin:  0 auto;
  font-size: 16px;
  margin-top: 22px;
  text-align: center;
  color: #BDEB00;
  background-color: #2D2D2D;
  cursor: pointer;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.route {
  width: 100%;
  display: flex;
  background-color: #151515;
  padding: 22px 16px;
}

.show {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: #E6E6E6;
  background-color: #2D2D2D;
  & > img {
    height: 24px;
    width: 24px;
  }
}

.r-title {
  font-weight: 700;
  font-size: 14px;
  color: #E6E6E6;
  margin: 8px 0;
}

.r-content {
  font-size: 12px;
  color: #B5B5B5;
  font-weight: 400;
  line-height: 15px;
  word-break: break-all;
  margin-bottom: 8px;
}

.info {
  background-color: #FFF;
}

.active {
  color: #EEE;
  background-color: #000;
}

.info-content {
  display: none;
}

.records {
  font-size: 12px;
  color: #3B3B3B;
  margin-top: 25px;
  display: flex;
  border: 1px solid #EFEFEF;
  background-color: #F6F6F6;
  & > .left {
    font-weight: 600;
    background-color: #FFF;
  }
  & > span {
    padding: 10px 5px;
  }
}

.footer-logo {
  display: flex;
  padding: 24px 0 42px 17px;
  background-color: #151515;
  & > img {
    height: 30px;
  }
}

.image-slot {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 200px;
  align-items: center;
}
</style>
