







import Vue from 'vue';
import TempLoading from '@/feature/components/tempLoading.vue';

export default Vue.extend({
  name: 'loading',
  components: {
    TempLoading
  },
  data: () => ({

  }),
  computed: {

  },
  mounted() {

  },
  methods: {

  }
})
