<template>
   <div class="footer">
    <div v-for="data in linkList" :key="data.imagelinks && data.imagelinks.filehash">
      <el-image
        lazy
        :src="spliceUrl(data.imagelinks && data.imagelinks.filehash)"
        :fit="'contain'"
        style="text-align: center;display: flex;align-items: center;justify-content: center;"
        @click="handleGoto(data.template_000.webpagelink)"
      >
        <div slot="error" style="height: 20px;">
          <img src="@/assets/remark/home.png" width="20" height="20" />
        </div>
      </el-image>
    </div>
  </div>
</template>

<script>
import { shortTxid } from '@/utils/bless';

export default {
  name: 'remark-footer',
  components: {},
  props: ['linkList'],
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {
    short(txId, before, after) {
      return shortTxid(txId, before, after);
    },
     handleGoto(link) {
      window.location.href = link;
    },
    spliceUrl(location) {
      return window.Config.bless_service_url + `/v1/public/attachment/${location}`;
    },
  }
}
</script>

<style lang="scss" scoped>

.footer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #151515;
  padding: 32px 17px 24px 17px;
  border-bottom: 1px solid #333;
  & > div {
    display: flex;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background-color: #CBFC00;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
  }
}

::v-deep .el-image {
  height: 40px;
  width: 40px;
  & > img {
    height: 40px;
    width: 40px;
  }
}

</style>
